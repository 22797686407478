import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

import { Card } from '@components/Card';
import { useCustomer } from '@hooks/Customer';
import { useJob } from '@hooks/Job';
import { JobStatus, UserType } from '@lib/firebase';
import { BookingField } from '@pages/Booking/components/BookingField';
import { BookingInvoice } from '@pages/Booking/components/BookingInvoicing';
import { BookingNotes } from '@pages/Booking/components/BookingNotes';
import { BookingStatus } from '@pages/Booking/components/BookingStatus';
import { BookingTimestamps, formatTimestamp } from '@pages/Booking/components/BookingTimestamps';
import { BookingVendorId } from '@pages/Booking/components/BookingVendorId';

import { BookingActions } from '../components/BookingActions';

/**
 * Returns Booking list item
 */
export const BookingDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { data: booking } = useJob(id);
  const { data: customer, isLoading: customerLoading } = useCustomer(); // TODO: Update when customer object is done

  if (!booking) {
    return null;
  }

  const customerName = customerLoading
    ? 'Loading...'
    : `${customer?.operating_name} - ${customer?.first_name} ${customer?.last_name}`;

  const { address1, address2, city, state, country, postal_code } = booking.location;
  const address = address2 ? `${address2}-${address1}` : address1;
  const location = [address, city, state, country, postal_code].join(', ');

  return (
    <Stack gap={2}>
      <Card>
        <Stack gap={2}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            gap={2}
            alignItems={{ xs: 'start', sm: 'center' }}
            justifyContent="space-between"
          >
            <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
              {booking.status !== JobStatus.Pending && (
                <BookingVendorId id={booking.id} vendor_order_id={booking.vendor_order_id} />
              )}
              <Typography variant="h4">{customerName}</Typography>
            </Stack>
            <BookingStatus status={booking.status} />
          </Stack>

          {/* Booking details */}
          <Stack direction="column" spacing={1} alignItems="stretch" sx={{ marginBottom: 1 }}>
            <BookingField
              label="Scheduled Start Time"
              value={formatTimestamp(booking.scheduled_time)}
            />
            <BookingField
              label="Scheduled End Time"
              value={formatTimestamp(booking.scheduled_end_time)}
            />
            <BookingField label="Address" value={location} />
            <BookingField
              label="Has union requirements"
              value={booking.is_unionized === true ? 'Yes' : 'No'}
            />
            <BookingField label="Payment terms" value={booking.payment_terms ?? 'Not specified'} />
            <BookingField
              label="Requested services(s) terms"
              value={
                <ul>
                  {booking.selected_categories.map((category) => (
                    <li key={category.id}>{category.name}</li>
                  ))}
                </ul>
              }
            />
          </Stack>
          <BookingActions
            id={booking.id}
            status={booking.status}
            taxProvince={booking.invoice?.tax_jurisdiction}
          />
        </Stack>
      </Card>

      <BookingTimestamps scheduled_time={booking.scheduled_time} timestamps={booking.timestamps} />

      <BookingNotes id={booking.id} notes={booking.notes} perspective={UserType.Vendor} />

      <BookingInvoice id={booking.id} invoice={booking.invoice} />
    </Stack>
  );
};
