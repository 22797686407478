import { Stack, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card } from '@components/Card';
import { PrimaryButton } from '@components/PrimaryButton';
import { useAddJobNote } from '@hooks/Job/useAddJobNote';
import { Field, JobNote, NoteField, UserType } from '@lib/firebase';
import { Color } from '@theme/palette';

export interface BookingNotesProps {
  id: string;
  notes: JobNote[];
  perspective: UserType;
}

export const BookingNotes = ({ id, notes, perspective }: BookingNotesProps) => {
  const { submit: addNote } = useAddJobNote();
  const [newNote, setNewNote] = useState<string>('');
  const navigate = useNavigate();

  const handleNewNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewNote(event.target.value);
    event.preventDefault();
  };

  const handleAddNote = async () => {
    await addNote({
      [Field.Id]: id,
      [NoteField.Text]: newNote,
      [NoteField.CreatorType]: perspective,
    });
    setNewNote('');
    navigate(0);
  };

  return (
    <Card>
      <Stack gap={4}>
        <Typography variant="h2">Chat</Typography>
        {notes.map((note) => {
          const isYou = note[NoteField.CreatorType] === perspective;
          return (
            <Stack gap={2} key={note.created_at.toDate().getTime()}>
              <Card
                style={{
                  textAlign: isYou ? 'right' : 'left',
                  backgroundColor: isYou ? Color.BackgroundBlue : Color.BackgroundGreen,
                }}
              >
                <>
                  <Typography variant="h6">{note[NoteField.CreatorType].toUpperCase()}</Typography>
                  <Typography variant="body1">{note[NoteField.Text]}</Typography>
                </>
              </Card>
              <Typography variant="caption" style={{ textAlign: 'right' }}>
                {format(note[NoteField.CreatedAt]?.toDate(), 'dd MMM, yyyy - hh:mm aaa')}
              </Typography>
            </Stack>
          );
        })}
        <Stack direction="row" gap={2} justifyContent="stretch" flexWrap="wrap">
          <TextField
            sx={{ flexGrow: 1 }}
            value={newNote}
            onChange={handleNewNoteChange}
            placeholder={`Message the ${perspective === 'customer' ? 'vendor' : 'customer'}...`}
          />
          <PrimaryButton onClick={handleAddNote}>Send</PrimaryButton>
        </Stack>
      </Stack>
    </Card>
  );
};
